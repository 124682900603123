function showToast(msg,duration){  
    duration=isNaN(duration)?3000:duration;  
    var m = document.createElement('div');
    // var b=document.getElementsByName('body');
    var b=document.getElementById('body');
    var theme=b.getAttribute('class');
    if(theme){
      m.setAttribute("class","toast_"+theme);
    }  
    
    m.innerHTML = msg;  
    // m.style.cssText="width:60%; min-width:180px; background:#000; opacity:0.6; height:auto;min-height: 30px; color:#fff; line-height:30px; text-align:center; border-radius:4px; position:fixed; top:90%; left:20%; z-index:999999;";  
    m.style.cssText="width:60%; min-width:180px;  opacity:0.6; height:auto;min-height: 30px;  line-height:30px; text-align:center; border-radius:4px; position:fixed; top:90%; left:20%; z-index:999999;"; 
    document.body.appendChild(m);  
    setTimeout(function() {  
        var d = 0.5;  
        m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';  
        m.style.opacity = '0';  
        setTimeout(function() { document.body.removeChild(m) }, d * 1000);  
    }, duration);  
  } 

  function showLoading(){
    var m = document.createElement('img');
    m.src('/assets/common/image/loading.gif');
    m.width(50);
    m.height(50);
    m.id('loading');
    m.style.cssText="position:absolute;left:-webkit-calc(50%-25px);left:-moz-calc(50% - 25px); left:calc(50% - 25px);top:170px;z-index:888;";
    document.body.appendChild(m);  
  }

  function closeLoading(){
    var m=document.getElementById('loading');
    if(m){
      document.body.removeChild(m);
    }
  }

      // $(function () {
      //     //手指触摸屏幕时触发事件
      //     $('body').on('touchstart', function () {
      //         // $('p').css({'color': '#f00'});
      //         console.log("用户手指触摸到屏幕...");
      //     });
      //     //手指在屏幕上移动时触发
      //     $('body').on('touchmove', function (even) {
      //         // $('p').css({'color': '#0f0'});
      //         console.log("用户手指在屏幕上移动...");
      //     });
      //     //手指离开屏幕时触发
      //     $('body').on('touchend', function () {
      //         // $('p').css({'color': '#00f'});
      //         console.log("用户手离开屏幕...");
      //     });
      // });