var os;
getCurrentOS();

function getCurrentOS() {
	var browser = {
		versions: function() {
			var u = navigator.userAgent,
				app = navigator.appVersion;
			return {
				trident: u.indexOf('Trident') > -1, //IE内核
				presto: u.indexOf('Presto') > -1, //opera内核
				webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
				gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
				mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
				ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
				android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
				iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
				iPad: u.indexOf('iPad') > -1, //是否iPad
				webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
				weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
				qq: u.match(/\sQQ/i) == " qq" //是否QQ
			};
		}(),
		language: (navigator.browserLanguage || navigator.language).toLowerCase()
	}

	if(browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
		os = 'ios';
	} else if(browser.versions.android) {
		os = 'android';
	} else {
		os = 'other';
	}
	return os;
}

// WebContainer  --->>NativeApp
// 3.2	JSLib Function - Login With App
// The function is useddelegate login request to Native APP
function loginWithApp(loginActionId, redirectURL){
	var jsonString= {"action": "loginWithApp"};
	jsonString.actionId = loginActionId;
	jsonString.redirectURL = redirectURL;
	jsonString = JSON.stringify(jsonString);
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}

// WebContainer  --->>NativeApp
// 3.3	JSLib Function - Notify Session Timeout (Web to App)
// When an expired session occurs in the Web Container, it will actively notify the App
function notifySessionTimeout(){
	var jsonString='{"action": "sessionExpired"}';
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}

// WebContainer  --->>NativeApp
// 3.5	JSLib Function - Need Exit The whole Webview
// When the Web Container finds that App user triggers an action to quit the Webview
function exitTheWebview(){
	var jsonString='{"action": "exitWeb"}';
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}

// WebContainer  --->>NativeApp
// 3.8	JSLib Function - Open App Page/Activity
// When the Web Container need open any page / activity in Native APP.
function openAppPage(pageName){
	var jsonObj = {"action": "openAppPage"};
	jsonObj["pageName"] = pageName;
	var jsonString = JSON.stringify(jsonObj);
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}

function openAppPage(pageName,utm){
	var jsonObj = {"action": "openAppPage"};
	jsonObj["pageName"] = pageName;
	jsonObj["utm"] = utm;
	var jsonString = JSON.stringify(jsonObj);
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}

// WebContainer  --->>NativeApp
// 3.9	JSLib Function - Login With App
// The function is useddelegate login request to Native APP
function getNetworkInfo(actionId){
	var jsonString= {"action": "getNetworkInfo"};
	jsonString.actionId = actionId;
	jsonString = JSON.stringify(jsonString);
	console.log("[" + actionId + "]start getNetworkInfo...");
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}

function pageOnload(url) {
	var jsonObj = {"action": "pageOnload"};
	jsonObj["loadURL"] = url;
	var jsonString = JSON.stringify(jsonObj);
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
				customWebToAppDataWithJson(jsonString);
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		
	}
}


// NativeApp  --->> WebContainer
function customAppToWebDataWithJson(str){
	var appToWebJson;
	try{
		if(typeof str == 'string'){
			appToWebJson = eval('(' + str + ')');
		} else {
			appToWebJson = str;
		}
		if(appToWebJson.action == "sessionExpired") {
			web5gJS.handleData(appToWebJson);
			var errdspt = apiErrorMessage("e2");
			showToast(errdspt);
			//3.4	JSLib Function - Notify Session Timeout (App to Web)
			//When an expired session occurs in App, it will actively notify the Web Container.
		}else if(appToWebJson.action == "openNewURL"){
			// customAppToWebDataWithJson('{"action": "openNewURL","redirectURL": "http://127.0.0.1:8848/web5gPortal/plan.html"}')
			redirectFunc(appToWebJson.redirectURL);
			//3.6	JSLib Function - Open Subpage in 5G Portal
			//Use this function when App need to open any subpage within 5G portal. 
			//Remarks: this method must be used after the Web Container has been successfully loadedby App (as described in section 3.1).

		}else if(appToWebJson.action == "clickBack"){
			//3.7	JSLib Function - Event of BackButton is Clicked
			//Every time the user clicks the back key of App, it will notify Web Container to do corresponding actions.
		}else if(appToWebJson.action == "loginWithAppResp"){
			if(appToWebJson.code == "0"){
				// userProfile.loginStatus = "logon";
				// userProfile.loginToken = appToWebJson.data.loginToken;
				// userProfile.loginName = appToWebJson.data.loginName;
				web5gJS.handleData(appToWebJson);
			}
		} else if(appToWebJson.action == "getNetworkInfoResp"){
			web5gJS.handleData(appToWebJson);
		} else if(appToWebJson.action == "5gAppStorage"){
			// alert(appToWebJson);
			// web5gJS.showToast(JSON.stringify(appToWebJson));
			if(appToWebJson.code == "0") {
				if (appToWebJson.actionId !== undefined) {
					var callback = callbackMap.get(appToWebJson.actionId);
					if (callback !== undefined) {
						if(appToWebJson['value'] == undefined) {
							callback();
						} else {
							callback(appToWebJson.value.replace(/\\\"/g, "\""));
						}
						callbackMap.delete(appToWebJson.actionId);
					}
				}
			} else {
				throw ("App Storage Error, ErrCode:" + appToWebJson.code)  
			}
		
		}else {
			if (appToWebJson.actionId !== undefined) {
				var callback = callbackMap.get(appToWebJson.actionId);
				if (callback !== undefined) {
					var data = appToWebJson["data"] == undefined ? appToWebJson : appToWebJson["data"];
					//alert(JSON.stringify(data));
					callback(data);
					callbackMap.delete(appToWebJson.actionId);
				}
			}
		}
	}catch(e){
		
	}
}

var callbackMap = new Map();

function customWebToAppData(actionName, actionData, callbackFunc) {
	var jsonData = actionData != undefined ? actionData : {};
	jsonData["action"] = actionName;
	if(callbackFunc != undefined){
		var actionId = Math.random() + "";
		callbackMap.set(actionId, callbackFunc);
		jsonData["actionId"] = actionId;
	}
	var jsonString = JSON.stringify(jsonData);
	try {
		if(os == 'ios') {
			try {
				window.webkit.messageHandlers.customWebToAppDataWithJson.postMessage(jsonString);
			} catch(e) {
			    console.error('ios error:'+e);
				// alert('ios error:'+e);
				customWebToAppDataWithJson(jsonString);
				throw e;
			}
		} else if(os == 'android') {
			customAppToWebDataWithJson(window.web5gAndroid.customWebToAppDataWithJson(jsonString));
		}
	} catch(e) {
		// alert('share error is '+e);
		console.error('error:'+e);
		throw e;
	}
}

function appStorageSet(key, value, callbackFunc) {
	appStorage("set", key, value.replace(/\"/g,"\\\""), callbackFunc);
}

function appStorageGet(key, callbackFunc) {
	appStorage("get", key, null, callbackFunc);
}

function appStorageRemove(key, callbackFunc) {
	appStorage("remove", key, null, callbackFunc);
}

function appStorageClear(callbackFunc) {
	appStorage("clear", null, null, callbackFunc);
}

function appStorage(type, key, value, callbackFunc) {
	var dataJsonStr = ('{"type":"' + type + '"') + 
	(key != null ? (',"key":"' + key + '"') : '') + 
	(value != null ? (',"value":"' + value + '"') : '') + '}';
	var data = JSON.parse(dataJsonStr);
	customWebToAppData("5gAppStorage", data, callbackFunc);
}