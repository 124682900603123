// Click event of the Share Button
$(".vaspage__share_img, .vaspage__share_img_tc").click(function(){
	var shareTitle = $(this).data("sharetitle");
	var shareMessage = $(this).data("sharemessage");
	var shareUrl = $(this).data("shareurl");
	var lang = $(this).data("lang");
	
	if (getOS() == "other") {
		webshare(shareTitle, shareMessage, shareUrl, lang);
	} else {
		nativeShare(shareTitle, shareMessage, shareUrl, lang);
	}
});



// Determine browser OS
function getOS() {
     var browser = {
               versions: function() {
                        var u = navigator.userAgent,
                                 app = navigator.appVersion;
                        return {
                                 trident: u.indexOf('Trident') > -1, 
                                 presto: u.indexOf('Presto') > -1, 
                                 webKit: u.indexOf('AppleWebKit') > -1,  
                                 gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,  
                                 mobile: !!u.match(/AppleWebKit.*Mobile.*/),  
                                 ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), 
                                 android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, 
                                 iPhone: u.indexOf('iPhone') > -1,  
                                 iPad: u.indexOf('iPad') > -1, 
                                 webApp: u.indexOf('Safari') == -1, 
                                 weixin: u.indexOf('MicroMessenger') > -1,  
                                 qq: u.match(/\sQQ/i) == " qq" 
                        };
               }(),
               language: (navigator.browserLanguage || navigator.language).toLowerCase()
     }

     if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
               return 'ios';
     } else if (browser.versions.android) {
               return 'android';
     } else {
               return 'other';
     }
}



// Share function for Web Version
function webshare(title, message, url, lang) {
	var msg = message;
	if(message.includes("(URL)")){
		msg = message.replace(/\(URL\)/, "\n" + url);
	}
	var whole_msg = title + "\n" + msg;
	var textarea = document.getElementById("webshare_content");
	textarea.value = whole_msg;
}



// Web Version Share, Copy Button Click event
$("#webshare_copybtn").click(function(){
	var content = document.getElementById("webshare_content");
	content.select();
	document.execCommand("copy");
	var toast = document.getElementById("alert_toast");
	toast.classList.add("show");

	setTimeout(function() {
		toast.classList.remove("show");
	}, 3000);
});



// Share function for App Version
function nativeShare(title, message, url, lang){
	var jsonObject = {"action": "nativeShare"};
	jsonObject["title"] = title;
	var msg = message;
	if(message.includes("(URL)")){
		msg = message.replace(/\(URL\)/, "\n" + url);
	}
	
	jsonObject["message"] = msg;
//	var os = getCurrentOS();
//	console.log('os:' + os);
    try{
	   customWebToAppData("nativeShare", jsonObject);
	}
	catch(err){
		throw err;
	}
	
}

// -> customWebToAppData(actionName, actionData, callbackFunc) in bridge.js